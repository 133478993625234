// Pass backend variables to js variables
/**
 * VARIABLES AND FUNCTIONS THAT NEED INITIATION
 */
let windowSize = window.innerWidth
let breakpoint = 992;
let dynamicImages = true



/**
 * EVENT LISTENERS
 */

// Execute something on 575 breakpoint (mobile to desktop and vice versa)

if (dynamicImages) {
  setBackgrounds();
  // mobileSizeForRene();
  removeBootstrap();
  window.addEventListener('resize', function(event) {
    if ((windowSize > breakpoint && window.innerWidth <= breakpoint) || (windowSize <= breakpoint && window.innerWidth > breakpoint)) {
      setBackgrounds();
      // mobileSizeForRene();
      removeBootstrap();
    }
    windowSize = window.innerWidth;
  }, true);

  /**
   * FUNCTIONS
   */

  // Get Sections Data and check if it has a background image
  function setBackgrounds() {
    let images = document.querySelectorAll(".responsive-background-image");

    let isMobile = window.innerWidth <= breakpoint
    images.forEach(image => {
      let imageSrc = image.style.backgroundImage ? image.style.backgroundImage : null;
      if (imageSrc) {
        image.style.backgroundImage = isMobile ? addSizeMobile(imageSrc) : removeSizeMobile(imageSrc)
      }
    });
  }

  function mobileSizeForRene() {
    let images = document.querySelectorAll(".divided-content-img")
    images.forEach(image => {
      if (image.src.includes('nestle-promo-rene')) {
        let isMobile = window.innerWidth <= breakpoint;

        if (isMobile) {
          image.src += '?size=mob-g';
        } else {
          image.src = image.src.replace('?size=mob-g', '');
        }
      }
    })

  }

  function removeBootstrap() {
    let reneContainer = document.querySelector("#ReNe-section .divided-container")
    let isMobile = window.innerWidth <= breakpoint;
    if (isMobile) {
      reneContainer.classList.remove('py-3')
    } else {
      reneContainer.classList.add('py-3')
    }
  }

  function addSizeMobile(imageUrl) {
    return imageUrl.replace('")', '?size=mob")');
  }

  function removeSizeMobile(imageUrl) {
    return imageUrl.replace('?size=mob")', '")');
  }
}